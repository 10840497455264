import React, { ReactChild, ReactChildren, useState } from "react";
import "./Accordion.scss";
import arrowDown from "@/img/ico/arrowDown.svg";

interface Accordion {
  className?: string;
  children: ReactChild[] | ReactChildren | ReactChildren[];
}

const Accordion = ({ children }: Accordion) => {
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <div
      onClick={() => setCollapsed(!isCollapsed)}
      className={`collapseRow ${isCollapsed ? "" : "closedRow"}`}
    >
      <div>{children}</div>
      <img src={arrowDown} alt={""} />
    </div>
  );
};

export default Accordion;
