import React from "react";
import { Link } from "gatsby";
import Button from "@/components/Button/button";
import Section from "@/components/Section/Section";
import Accordion from "@/components/Accordion/Accordion";
import Col from "@/components/Col/Col";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import CustomRange from "@/components/CustomRange/CustomRange";
import FunctionsTable from "@/components/FunctionsTable/FunctionsTable";
import Title from "@/components/Title/Title";
import qa_photo from "@/img/prizing_qa.jpeg";
import testimonial1 from "@/img/testimonial/testimonial__1.jpg";
import testimonial2 from "@/img/testimonial/testimonial__2.jpg";
import testimonial3 from "@/img/testimonial/testimonial__3.jpg";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const Pricing = () => {
  return (
    <main>
      <CookieCompliance />
      <Header isPricing alwaysFixed></Header>
      <div className="spacerHeaderFixed"></div>

      <Section className="prizing" id="prizing">
        <Col size="100">
          <div
            className="headline sal-animate"
            data-sal="slide-up"
            data-sal-duration="550"
            data-sal-delay="0"
            data-sal-easing="ease-out"
          >
            <h6>Accurate tracking starting from just $99</h6>
            <h1>
              Find the perfect plan based on the Ads Revenue{" "}
              <span className="violet">tracked</span>.
            </h1>
          </div>

          <CustomRange></CustomRange>
        </Col>
      </Section>

      <Section className="prizing__features">
        <h3>Check out all the features</h3>
        <FunctionsTable />
      </Section>

      <Section className="prizing__qa">
        <div>
          <h6>Have questions?</h6>
          <h2>Follow the most frequent ones</h2>
        </div>

        <div className="colWrap">
          <Col size="50">
            <Accordion>
              <h5>What does Ads revenue tracked means?</h5>
              <div className="Accordion__textWrap">
                <p>
                  It’s your revenue that comes only from ads that we track. It
                  doesn’t include other sources of your revenue - i.e. email
                  marketing, organic search. For example, if your store is
                  making $100,000 revenue a month and $15,000 comes from
                  Facebook ads and the rest is organic, then $99 licence will be
                  sufficient for you.
                </p>
              </div>
            </Accordion>

            <Accordion>
              <h5>How long will it take to install ecomtrack?</h5>
              <div className="Accordion__textWrap">
                <p>
                  Our simple copy & paste solution take report staff so if you
                  need any help setting up your account, you can contact us from
                  your dashboard!
                </p>
              </div>
            </Accordion>

            <Accordion>
              <h5>Do you have any setup fees?</h5>
              <div className="Accordion__textWrap">
                <p>Hell no! Zero setup fees.</p>
              </div>
            </Accordion>

            <Accordion>
              <h5>Can I schedule a demo of ecomtrack?</h5>
              <div className="Accordion__textWrap">
                <p>
                  Of course! Book your demo call{" "}
                  <Link to="/demo-call/">here</Link>.
                </p>
              </div>
            </Accordion>

            <Accordion>
              <h5>
                Do you offer any affiliate program if I want to share ecomtrack
                with others?
              </h5>
              <div className="Accordion__textWrap">
                <p>
                  Yes! If you love using ecomtrack and would like to share it
                  with others, we want to thank-you by giving you 15% recurring
                  commission from every person you recommend to us as long as
                  they stay.
                </p>
              </div>
            </Accordion>

            <p className="prizing__qa__cta">
              If you have any other question, please do not hesitate to{" "}
              <a href="/contact">contact us</a>.
            </p>
          </Col>

          <Col size="50">
            <img src={qa_photo} />
          </Col>
        </div>
      </Section>

      <Section className="prizing__testimonial">
        <Title
          subtitle="Testimonials"
          maintitle="Our customers are lovin' ecomtrack"
        ></Title>

        <div className="prizing__testimonial__items">
          <Col size="33">
            <img src={testimonial1} />
            <h4>Jorge A.</h4>
            <p>Agency Owner</p>
            <p>
              Awesome software, lots of softwares out there with terrible
              tracking and bad UI overall, super easy to use, loved this one.
            </p>
          </Col>

          <Col size="33">
            <img src={testimonial3} />
            <h4>Ivan Z.</h4>
            <p>Manager</p>
            <p>
              <strong>
                $130k+ underreported by FB... ecomtrack solved it!
              </strong>
              I've been using ecomtrack for 3 months, and it's been absolute
              game-changer. Facebook ads were misattributing so much for my
              store which made it nearly impossible to scale. Now we use
              ecomtrack, we were able to double our ad budget and triple our
              revenue in the past 90 days!
            </p>
          </Col>

          <Col size="33">
            <img src={testimonial2} />
            <h4>Michal H.</h4>
            <p>Store Owner</p>
            <p>
              <strong>
                I never realized, how inaccurate Facebook data are.
              </strong>
              Ecomtrack gave me accurate and transparent data. And the best
              feature? The 28 days attribution window. Thanks guys !!!!
            </p>
          </Col>
        </div>

        <div className="prizing__ready">
          <Col size="50">
            <h3>Ready to start tracking?</h3>
          </Col>
          <Col size="50">
            <Button
              {...{
                variant: "blue",
                link: `${global.app_url}/register`,
                text: "Start tracking",
              }}
            />
          </Col>
        </div>
      </Section>

      <Footer colFlex></Footer>
    </main>
  );
};
export default Pricing;
